import React from 'react'
import PropTypes from 'prop-types'

import styled from 'styled-components'

import {
  Box,
  Card,
  CardContent,
  CardMedia,
  Typography,
} from '@material-ui/core'

import materialUiTheme, { fonts } from '../material-ui-theme'
import media from '../utils/media'

const FeaturedMenuCard = props => {
  const { data } = props

  const { description, imageSrc, price, title } = data

  return (
    <S.FeaturedMenuCard raised>
      <S.FeaturedMenuCardMedia image={imageSrc} title={title} />
      <S.FeaturedMenuCardContent>
        <Box alignItems="center" display="flex">
          <S.FeaturedMenuCardContentTitle>
            {title}
          </S.FeaturedMenuCardContentTitle>
          <Box
            borderTop={`1px dashed ${materialUiTheme.palette.primary.light}`}
            flex={1}
            height="1px"
            ml={2}
            mr={1}
            position="relative"
          />
          <S.PriceText>{price}</S.PriceText>
        </Box>
        {description && (
          <S.DescriptionText
            gutterBottom
            style={{ marginBottom: materialUiTheme.spacing(3) }}
          >
            {description}
          </S.DescriptionText>
        )}
      </S.FeaturedMenuCardContent>
    </S.FeaturedMenuCard>
  )
}

const S = {
  FeaturedMenuCard: styled(Card)`
    background: ${materialUiTheme.palette.common.transparent};
    box-shadow: none;

    ${media.sm`
        display: flex;
        margin-left: auto;
        margin-right: auto;
        max-width: 588px;
        min-height: 248px;
    `}
  `,
  FeaturedMenuCardMedia: styled(CardMedia)`
    background-size: contain;
    height: 148px;

    ${media.sm`
      flex: 4;
      margin: auto;
      height: 200px;
    `}

    ${media.md`
      flex: 5;
    `}
  `,
  FeaturedMenuCardContent: styled(CardContent)`
    background-color: ${materialUiTheme.palette.common.transparent};

    ${media.sm`
      display: flex;
      flex: 8;
      flex-direction: column;
      justify-content: center;
    `}

    ${media.md`
      flex: 7;
    `}
  `,
  FeaturedMenuCardContentTitle: styled(Typography).attrs({
    color: 'primary',
    gutterBottom: true,
    variant: 'h5',
  })`
    font-family: ${fonts['Belmar-Normal']};
    font-size: ${materialUiTheme.typography.h6.fontSize};
    max-width: 120px;

    ${media.sm`
      font-size: ${materialUiTheme.typography.h5.fontSize};
      max-width: 200px;
    `}
  `,

  DescriptionText: styled(Typography).attrs({
    varian: 'body1',
  })`
    ${media.sm`
      max-width: 280px;
    `}
  `,
  PriceText: styled(Typography).attrs({
    color: 'primary',
  })`
    color: ${materialUiTheme.palette.primary.main};
    font-family: ${fonts['Belmar-Bold']};
    font-size: ${materialUiTheme.typography.body1.fontSize};
    min-width: 40px;
  `,
}

FeaturedMenuCard.propTypes = {
  data: PropTypes.shape({
    description: PropTypes.string,
    imageSrc: PropTypes.string.isRequired,
    price: PropTypes.string.isRequired,
    title: PropTypes.string.isRequired,
  }).isRequired,
}

export default FeaturedMenuCard
