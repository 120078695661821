import React from 'react'

import { graphql, useStaticQuery } from 'gatsby'
import styled from 'styled-components'

import {
  AppBar,
  Box,
  Grid,
  Tab,
  Tabs,
  Typography,
  useMediaQuery,
  useTheme,
} from '@material-ui/core'
import { Fade, Slide, Zoom } from 'react-reveal'

import ContainedSection from '../components/ContainedSection'
import FeaturedMenuCard from '../components/FeaturedMenuCard'
import Layout from '../components/Layout'
import SEO from '../components/seo'

import materialUiTheme, { fonts } from '../material-ui-theme'

import media from '../utils/media'

const Menu = props => {
  const data = useStaticQuery(graphql`
    query {
      beefMamiImage: file(relativePath: { eq: "menu/mami/beef-mami.png" }) {
        childImageSharp {
          fluid(maxWidth: 256) {
            ...GatsbyImageSharpFluid
          }
        }
      }
      beefWantonMamiImage: file(
        relativePath: { eq: "menu/mami/beef-wanton-mami.png" }
      ) {
        childImageSharp {
          fluid(maxWidth: 256) {
            ...GatsbyImageSharpFluid
          }
        }
      }
      maBinondoMamiImage: file(
        relativePath: { eq: "menu/mami/ma-binondo-mami.png" }
      ) {
        childImageSharp {
          fluid(maxWidth: 256) {
            ...GatsbyImageSharpFluid
          }
        }
      }
      maChickenMamiSImage: file(
        relativePath: { eq: "menu/mami/ma-chicken-mami-s.png" }
      ) {
        childImageSharp {
          fluid(maxWidth: 256) {
            ...GatsbyImageSharpFluid
          }
        }
      }
      maChickenWantonMamiImage: file(
        relativePath: { eq: "menu/mami/ma-chicken-wanton-mami.png" }
      ) {
        childImageSharp {
          fluid(maxWidth: 256) {
            ...GatsbyImageSharpFluid
          }
        }
      }
      siomaiMamiSImage: file(
        relativePath: { eq: "menu/mami/siomai-mami-s.png" }
      ) {
        childImageSharp {
          fluid(maxWidth: 256) {
            ...GatsbyImageSharpFluid
          }
        }
      }

      kikiam1RollImage: file(
        relativePath: { eq: "menu/dimsum/kikiam-1roll.png" }
      ) {
        childImageSharp {
          fluid(maxWidth: 256) {
            ...GatsbyImageSharpFluid
          }
        }
      }
      siomaiPair2pcsImage: file(
        relativePath: { eq: "menu/dimsum/siomai-pair-2pcs.png" }
      ) {
        childImageSharp {
          fluid(maxWidth: 256) {
            ...GatsbyImageSharpFluid
          }
        }
      }
      lumpiangShanghai3pcsImage: file(
        relativePath: { eq: "menu/dimsum/lumpiang-shanghai-3pcs.png" }
      ) {
        childImageSharp {
          fluid(maxWidth: 256) {
            ...GatsbyImageSharpFluid
          }
        }
      }
      asadoSiopaoImage: file(
        relativePath: { eq: "menu/dimsum/asado-siopao.png" }
      ) {
        childImageSharp {
          fluid(maxWidth: 256) {
            ...GatsbyImageSharpFluid
          }
        }
      }
      pinsecFrito5pcsImage: file(
        relativePath: { eq: "menu/dimsum/pinsec-frito-5pcs.png" }
      ) {
        childImageSharp {
          fluid(maxWidth: 256) {
            ...GatsbyImageSharpFluid
          }
        }
      }
      chickenWithSausageSiopaoImage: file(
        relativePath: { eq: "menu/dimsum/chicken-with-sausage-siopao.png" }
      ) {
        childImageSharp {
          fluid(maxWidth: 256) {
            ...GatsbyImageSharpFluid
          }
        }
      }
      bolaBolaSiopaoImage: file(
        relativePath: { eq: "menu/dimsum/bola-bola-siopao.png" }
      ) {
        childImageSharp {
          fluid(maxWidth: 256) {
            ...GatsbyImageSharpFluid
          }
        }
      }
      honeyCuredPorkPaoImage: file(
        relativePath: { eq: "menu/dimsum/honey-cured-pork-pao.png" }
      ) {
        childImageSharp {
          fluid(maxWidth: 256) {
            ...GatsbyImageSharpFluid
          }
        }
      }
      bonelessFriedChickenWithRiceImage: file(
        relativePath: {
          eq: "menu/over-rice-in-hotpot/boneless-fried-chicken-with-rice.png"
        }
      ) {
        childImageSharp {
          fluid(maxWidth: 256) {
            ...GatsbyImageSharpFluid
          }
        }
      }
      lumpiangShanghaiWithRiceImage: file(
        relativePath: {
          eq: "menu/over-rice-in-hotpot/lumpiang-shanghai-with-rice.png"
        }
      ) {
        childImageSharp {
          fluid(maxWidth: 256) {
            ...GatsbyImageSharpFluid
          }
        }
      }
      specialKikiamWithRiceImage: file(
        relativePath: {
          eq: "menu/over-rice-in-hotpot/special-kikiam-with-rice.png"
        }
      ) {
        childImageSharp {
          fluid(maxWidth: 256) {
            ...GatsbyImageSharpFluid
          }
        }
      }
      sweetAndSourPorkWithRiceImage: file(
        relativePath: {
          eq: "menu/over-rice-in-hotpot/sweet-and-sour-pork-with-rice.png"
        }
      ) {
        childImageSharp {
          fluid(maxWidth: 256) {
            ...GatsbyImageSharpFluid
          }
        }
      }
      crispyPiencitCantonWithFriedBreadImage: file(
        relativePath: {
          eq: "menu/good-for-two/crispy-piencit-canton-with-fried-bread.png"
        }
      ) {
        childImageSharp {
          fluid(maxWidth: 256) {
            ...GatsbyImageSharpFluid
          }
        }
      }
      crispyPiencitCantonImage: file(
        relativePath: { eq: "menu/good-for-two/crispy-piencit-canton.png" }
      ) {
        childImageSharp {
          fluid(maxWidth: 256) {
            ...GatsbyImageSharpFluid
          }
        }
      }
      mamiAtSiopaoImage: file(
        relativePath: { eq: "menu/good-for-two/mami-at-siopao.png" }
      ) {
        childImageSharp {
          fluid(maxWidth: 256) {
            ...GatsbyImageSharpFluid
          }
        }
      }

      eggCustardImage: file(
        relativePath: { eq: "menu/dessert/egg-custard.png" }
      ) {
        childImageSharp {
          fluid(maxWidth: 256) {
            ...GatsbyImageSharpFluid
          }
        }
      }
      haloHaloImage: file(relativePath: { eq: "menu/dessert/halo-halo.png" }) {
        childImageSharp {
          fluid(maxWidth: 256) {
            ...GatsbyImageSharpFluid
          }
        }
      }
      maisConAlmondImage: file(
        relativePath: { eq: "menu/dessert/mais-con-almond.png" }
      ) {
        childImageSharp {
          fluid(maxWidth: 256) {
            ...GatsbyImageSharpFluid
          }
        }
      }

      blackGulamanWithSagoImage: file(
        relativePath: { eq: "menu/drinks/black-gulaman-with-sago.png" }
      ) {
        childImageSharp {
          fluid(maxWidth: 256) {
            ...GatsbyImageSharpFluid
          }
        }
      }
      houseBlendImage: file(
        relativePath: { eq: "menu/drinks/house-blend.png" }
      ) {
        childImageSharp {
          fluid(maxWidth: 256) {
            ...GatsbyImageSharpFluid
          }
        }
      }
      icedTeaImage: file(relativePath: { eq: "menu/drinks/iced-tea.png" }) {
        childImageSharp {
          fluid(maxWidth: 256) {
            ...GatsbyImageSharpFluid
          }
        }
      }
      lemonadeImage: file(relativePath: { eq: "menu/drinks/lemonade.png" }) {
        childImageSharp {
          fluid(maxWidth: 256) {
            ...GatsbyImageSharpFluid
          }
        }
      }
      mangoShakeImage: file(
        relativePath: { eq: "menu/drinks/mango-shake.png" }
      ) {
        childImageSharp {
          fluid(maxWidth: 256) {
            ...GatsbyImageSharpFluid
          }
        }
      }
      redGulamanWithSagoImage: file(
        relativePath: { eq: "menu/drinks/red-gulaman-with-sago.png" }
      ) {
        childImageSharp {
          fluid(maxWidth: 256) {
            ...GatsbyImageSharpFluid
          }
        }
      }
    }
  `)

  const menuData = [
    /**
     * Mami
     */
    {
      id: 1,
      category: SpecialtyCategory.MAMI,
      imageSrc: data.maChickenMamiSImage.childImageSharp.fluid.src,
      description: `A bowl of freshly cooked meaty chicken slices in our special noodle soup.`,
      price: '129',
      title: `Ma's Chicken Mami (S)`,
    },
    {
      id: 2,
      category: SpecialtyCategory.MAMI,
      imageSrc: data.siomaiMamiSImage.childImageSharp.fluid.src,
      description: `One of the most popular dimsums, Pork Siomai, served over freshly cooked noodles and hot rich stock.`,
      title: 'Siomai Mami (S)',
      price: '129',
    },
    {
      id: 3,
      category: SpecialtyCategory.MAMI,
      imageSrc: data.beefMamiImage.childImageSharp.fluid.src,
      description: `A bowl of freshly cooked noodles in warm flavorful soup topped with our signature Nanking Beef.`,
      title: `Beef Mami`,
      price: '173',
    },
    {
      id: 4,
      category: SpecialtyCategory.MAMI,
      imageSrc: data.maChickenWantonMamiImage.childImageSharp.fluid.src,
      description: `Freshly cooked wanton and chicken meat in a bowl of noodles and warm flavorful broth.`,
      price: '173',
      title: `Ma's Chicken Wanton Mami`,
    },
    {
      id: 5,
      category: SpecialtyCategory.MAMI,
      imageSrc: data.beefWantonMamiImage.childImageSharp.fluid.src,
      description: `A combination of tender beef brisket and pork dumplings drenched in a bowl of noodles and hot beef stock.`,
      title: 'Beef Wanton Mami',
      price: '173',
    },
    {
      id: 6,
      category: SpecialtyCategory.MAMI,
      imageSrc: data.maBinondoMamiImage.childImageSharp.fluid.src,
      description: `A bowl of rich noodle soup topped with chicken slices, pork siomai, and roasted pork garnished with Chinese cabbage and hard-boiled egg.`,
      price: '173',
      title: `Ma's Binondo Mami`,
    },
    /**
     * Dimsum
     */
    {
      id: 7,
      category: SpecialtyCategory.DIMSUM,
      imageSrc: data.kikiam1RollImage.childImageSharp.fluid.src,
      title: `Kikiam`,
      price: '113',
    },
    {
      id: 8,
      category: SpecialtyCategory.DIMSUM,
      imageSrc: data.siomaiPair2pcsImage.childImageSharp.fluid.src,
      title: `Siomai`,
      price: '43',
    },
    {
      id: 9,
      category: SpecialtyCategory.DIMSUM,
      imageSrc: data.lumpiangShanghai3pcsImage.childImageSharp.fluid.src,
      title: `Lumpiang Shanghai`,
      price: '119',
    },
    {
      id: 10,
      category: SpecialtyCategory.DIMSUM,
      imageSrc: data.asadoSiopaoImage.childImageSharp.fluid.src,
      title: `Asado Siopao`,
      price: '74',
    },
    {
      id: 11,
      category: SpecialtyCategory.DIMSUM,
      imageSrc: data.pinsecFrito5pcsImage.childImageSharp.fluid.src,
      title: `Pinsec Frito`,
      price: '108',
    },
    {
      id: 12,
      category: SpecialtyCategory.DIMSUM,
      imageSrc: data.chickenWithSausageSiopaoImage.childImageSharp.fluid.src,
      title: `Chicken w/ Sausage Siopao`,
      price: '74',
    },
    {
      id: 13,
      category: SpecialtyCategory.DIMSUM,
      imageSrc: data.bolaBolaSiopaoImage.childImageSharp.fluid.src,
      title: `Bola-Bola Siopao`,
      price: '74',
    },
    {
      id: 14,
      category: SpecialtyCategory.DIMSUM,
      imageSrc: data.honeyCuredPorkPaoImage.childImageSharp.fluid.src,
      title: `Honey Cured Pork Pao`,
      price: '74',
    },

    /**
     * Over Rice in Hot Pot
     */
    {
      id: 15,
      category: SpecialtyCategory.OVER_RICE_IN_HOT_POT,
      imageSrc:
        data.bonelessFriedChickenWithRiceImage.childImageSharp.fluid.src,
      description: `Wok-fried, juicy chicken pieces, served with a distilled vinaigrette dressing.`,
      title: 'Boneless Fried Chicken w/ Rice',
      price: '143',
    },
    {
      id: 16,
      category: SpecialtyCategory.OVER_RICE_IN_HOT_POT,
      imageSrc: data.sweetAndSourPorkWithRiceImage.childImageSharp.fluid.src,
      description: `Deep fried, Cantonese style crispy pork fillet coated liberally with tangy sweet and sour sauce, pineapple and green bell pepper. A delight in every bite! Century old perfected recipe.`,
      title: 'Sweet & Sour Pork w/ Rice',
      price: '128',
    },
    {
      id: 17,
      category: SpecialtyCategory.OVER_RICE_IN_HOT_POT,
      imageSrc: data.lumpiangShanghaiWithRiceImage.childImageSharp.fluid.src,
      description: `Sesame coated, hand rolled, protein packed. Popular in every Filipino festivity!`,
      title: 'Lumpiang Shanghai w/ Rice',
      price: '109',
    },
    {
      id: 18,
      category: SpecialtyCategory.OVER_RICE_IN_HOT_POT,
      imageSrc: data.specialKikiamWithRiceImage.childImageSharp.fluid.src,
      description: `Specially marinated premium Fujian pork beancurd roll. Heirloom recipe of Ma Estela since 1954.`,
      title: 'Special Kikiam w/ Rice',
      price: '128',
    },

    /**
     * Good for Two
     */
    {
      id: 19,
      category: SpecialtyCategory.GOOD_FOR_TWO,
      imageSrc: data.crispyPiencitCantonImage.childImageSharp.fluid.src,
      title: 'Crispy Piencit Canton',
      price: '208',
    },
    {
      id: 20,
      category: SpecialtyCategory.GOOD_FOR_TWO,
      imageSrc:
        data.crispyPiencitCantonWithFriedBreadImage.childImageSharp.fluid.src,
      title: 'Crispy Piencit Canton w/ Fried Bread',
      price: '239',
    },
    {
      id: 21,
      category: SpecialtyCategory.GOOD_FOR_TWO,
      imageSrc: data.mamiAtSiopaoImage.childImageSharp.fluid.src,
      title: 'Mami at Siopao',
      price: '283',
    },

    /**
     * Dessert
     */
    {
      id: 22,
      category: SpecialtyCategory.DESSERTS,
      imageSrc: data.eggCustardImage.childImageSharp.fluid.src,
      title: 'Egg Custard',
      price: '85',
    },
    {
      id: 23,
      category: SpecialtyCategory.DESSERTS,
      imageSrc: data.haloHaloImage.childImageSharp.fluid.src,
      title: 'Halo-Halo',
      price: '109',
    },
    {
      id: 24,
      category: SpecialtyCategory.DESSERTS,
      imageSrc: data.maisConAlmondImage.childImageSharp.fluid.src,
      title: 'Mais Con Almond',
      price: '85',
    },

    /**
     * Drinks
     */
    {
      id: 25,
      category: SpecialtyCategory.DRINKS,
      imageSrc: data.blackGulamanWithSagoImage.childImageSharp.fluid.src,
      title: 'Black Gulaman w/ Sago',
      price: '90',
    },
    {
      id: 26,
      category: SpecialtyCategory.DRINKS,
      imageSrc: data.houseBlendImage.childImageSharp.fluid.src,
      title: 'House Blend',
      price: '78',
    },
    {
      id: 27,
      category: SpecialtyCategory.DRINKS,
      imageSrc: data.icedTeaImage.childImageSharp.fluid.src,
      title: 'Iced Tea',
      price: '52',
    },
    {
      id: 28,
      category: SpecialtyCategory.DRINKS,
      imageSrc: data.lemonadeImage.childImageSharp.fluid.src,
      title: 'Lemonade',
      price: '90',
    },
    {
      id: 29,
      category: SpecialtyCategory.DRINKS,
      imageSrc: data.mangoShakeImage.childImageSharp.fluid.src,
      title: 'Mango Shake',
      price: '86',
    },
    {
      id: 30,
      category: SpecialtyCategory.DRINKS,
      imageSrc: data.redGulamanWithSagoImage.childImageSharp.fluid.src,
      title: 'Red Gulaman w/ Sago',
      price: '90',
    },
  ]

  const theme = useTheme()
  const isSpecialtiesScrollable = useMediaQuery(theme.breakpoints.down('md'))

  const [value, setValue] = React.useState(0)

  const handleChange = (event, newValue) => {
    setValue(newValue)
  }

  return (
    <Layout>
      <SEO title="Menu" />
      <S.ContainedSection>
        <Box color="primary.light" textAlign="center">
          <Zoom>
            <Typography variant="h4">
              Our Menu
            </Typography>
          </Zoom>
        </Box>
        <Fade top>
          <S.AppBar position="static">
            <S.Tabs
              indicatorColor="primary"
              scrollButtons={isSpecialtiesScrollable ? 'on' : 'off'}
              textColor="primary"
              value={value}
              variant={isSpecialtiesScrollable ? 'scrollable' : 'standard'}
              onChange={handleChange}
            >
              <S.Tab label="Mami" />
              <S.Tab label="Dimsum" />
              <S.Tab label="Over rice in Hot pot" />
              <S.Tab label="Good for two" />
              <S.Tab label="Desserts" />
              <S.Tab label="Drinks" />
            </S.Tabs>
          </S.AppBar>
        </Fade>
        <TabPanel index={SpecialtyCategory.MAMI} value={value}>
          <SpecialtiesTabPanelContent
            data={{
              category: SpecialtyCategory.MAMI,
              featuredMenu: menuData,
            }}
          />
        </TabPanel>
        <TabPanel index={SpecialtyCategory.DIMSUM} value={value}>
          <SpecialtiesTabPanelContent
            data={{
              category: SpecialtyCategory.DIMSUM,
              featuredMenu: menuData,
            }}
          />
        </TabPanel>
        <TabPanel index={SpecialtyCategory.OVER_RICE_IN_HOT_POT} value={value}>
          <SpecialtiesTabPanelContent
            data={{
              category: SpecialtyCategory.OVER_RICE_IN_HOT_POT,
              featuredMenu: menuData,
            }}
          />
        </TabPanel>
        <TabPanel index={SpecialtyCategory.GOOD_FOR_TWO} value={value}>
          <SpecialtiesTabPanelContent
            data={{
              category: SpecialtyCategory.GOOD_FOR_TWO,
              featuredMenu: menuData,
            }}
          />
        </TabPanel>
        <TabPanel index={SpecialtyCategory.DESSERTS} value={value}>
          <SpecialtiesTabPanelContent
            data={{
              category: SpecialtyCategory.DESSERTS,
              featuredMenu: menuData,
            }}
          />
        </TabPanel>
        <TabPanel index={SpecialtyCategory.DRINKS} value={value}>
          <SpecialtiesTabPanelContent
            data={{
              category: SpecialtyCategory.DRINKS,
              featuredMenu: menuData,
            }}
          />
        </TabPanel>
      </S.ContainedSection>
    </Layout>
  )
}

const S = {
  AppBar: styled(AppBar)`
    background-color: transparent;
    box-shadow: none;
    margin-top: ${materialUiTheme.spacing(5)}px;
    margin-bottom: ${materialUiTheme.spacing(5)}px;

    .MuiTabs-scrollButtons {
      color: ${materialUiTheme.palette.primary.light};
    }
  `,
  ContainedSection: styled(ContainedSection)`
    margin-top: 140px;

    ${media.sm`
      margin-top: 120px;
    `}

    ${media.md`
      margin-top: 80px;
    `}
  `,
  Tab: styled(Tab)`
    color: ${materialUiTheme.palette.secondary.light};
    font-size: ${materialUiTheme.typography.h6.fontSize};
    text-transform: capitalize;

    &.Mui-selected {
      color: ${materialUiTheme.palette.secondary.dark};
      font-family: ${fonts['BelmarCnd-Bold']};
    }
  `,
  Tabs: styled(Tabs)`
    .MuiTabs-indicator {
      background-color: ${materialUiTheme.palette.secondary.dark};
    }

    ${media.sm`
      margin-left: ${materialUiTheme.spacing(5)}px;
      margin-right: ${materialUiTheme.spacing(5)}px;
    `}

    ${media.md`
      margin-left: ${materialUiTheme.spacing(10)}px;
      margin-right: ${materialUiTheme.spacing(10)}px;
    `}

    ${media.lg`
      margin-left: ${materialUiTheme.spacing(12)}px;
      margin-right: ${materialUiTheme.spacing(12)}px;
    `}
  `,
}

function SpecialtiesTabPanelContent(props) {
  const { data } = props

  const { category, featuredMenu } = data

  return (
    <Grid container spacing={5}>
      {featuredMenu
        .filter(({ category: iCategory }) => iCategory === category)
        .map((iteratee, index) => {
          const isEven = index % 2 === 0

          return (
            <Grid key={iteratee.id} item xs={12} md={6}>
              <Slide left={isEven} right={!isEven}>
                <FeaturedMenuCard data={iteratee} />
              </Slide>
            </Grid>
          )
        })}
    </Grid>
  )
}

function TabPanel(props) {
  const { children, value, index, ...other } = props

  return (
    <Typography
      component="div"
      role="tabpanel"
      hidden={value !== index}
      id={`scrollable-force-tabpanel-${index}`}
      aria-labelledby={`scrollable-force-tab-${index}`}
      {...other}
    >
      <Box>{children}</Box>
    </Typography>
  )
}

const SpecialtyCategory = Object.freeze({
  MAMI: 0,
  DIMSUM: 1,
  OVER_RICE_IN_HOT_POT: 2,
  GOOD_FOR_TWO: 3,
  DESSERTS: 4,
  DRINKS: 5,
})

export default Menu
